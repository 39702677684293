/*
	Animation: bottomUp
	Source: Travis Nielson */

// Initial Class
%animationstart_bottomUp {
	@include size(100%);
	@include position(absolute, 100% 0px null 0px);
	@include transition; }

// Animation Class
%bottomUp {
	opacity: 1;
	top: 0%; }
