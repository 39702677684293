/*
	Animation: leftRight
	Source: Dylan Spicker (but like really, no skill needed here) */

// Initial Class
%animationstart_leftRight {
	top: 0%;
	left: -100%;
	@include size(100%);
	@include transition(800ms); }

// Animation Class
%leftRight {
	left: 0%; }
