form {
  max-width: 450px;
  margin: 0 auto 100px;
  padding: 0 20px;

  input, textarea {
    border: 3px solid $accent;
    width: 100%;
    @include box-sizing(border-box);
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;

    &:focus {
      outline: none;
      border-color: $accent-light; } }

  textarea {
    height: 10em; }

  .submit-wrap {
    text-align: right; }

  [type="submit"] {
    background: $accent;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
    width: 33%;

    &:hover {
      background: $accent-light; } }





  .contact-info-group {

    label {
      width: 50%;
      float: left;
      @include box-sizing(border-box);

      &:nth-child(1) {
        padding-right: 10px; }

      &:nth-child(2) {
        padding-left: 10px; } } }

  label {
    display: block;
    margin-bottom: 20px;

    span {
      display: block; } } }
