@import 'bounceOut';
@import 'fadeIn';
@import 'bottomUp';
@import 'none';
@import 'topDown';
@import 'bottomDoor';
@import 'topDoor';
@import 'leftRight';
@import 'rightLeft';
@import 'backgroundIn';
@import 'textSlide';
