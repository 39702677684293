///////////////////////
//
// Variables
//
///////////////////////

//------------------------------------------------
// Transition the image paths from the settings file to usable SASS variables
$animation: bounceOut;
$logo-path: 'NHLogo.svg';
$hero-path: 'sun-bg.jpg';
$avatar-path: 'NHFace.jpg';

$projects: "proj-1", "proj-2", "proj-3", "proj-4", "proj-5", "proj-6", "proj-7", "proj-8";

//------------------------------------------------
// Set some colors as sass variables

$accent: #c99065;
$accent-light: #e1c184;
$bgcolor: #e5eaee;

$documentBackgroundColor: #fff;
$headlineColor: #333;
$textColor: #666;
$linkColor: #c99065;
$selectionColor: #ffff9e;


///////////////////////////
//
// SASS IMPORTS
//
///////////////////////////

@import '1-tools/-tools-dir';
@import '2-base/-base-dir';
@import '3-sections/-sections-dir';
