/*
	Animation: backgroundIn
	Source: Dylan Spicker (but like really, no skill needed here) */

// Initial Class
%animationstart_backgroundIn {
	top: 0%;
	@include size(100%);
	@include transition(800ms);
	background: transparent !important;

	& strong {
		@include transition(800ms);
		text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; } }

// Animation Class
%backgroundIn {
	left: 0%;
	background: fade-out($accent, 0.4) !important; }
