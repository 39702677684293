/*
	Animation: fadeIn
	Source: Dylan Spicker (but like really, no skill needed here) */

// Initial Class
%animationstart_fadeIn {
	opacity: 0;
	@include transition(opacity 500ms linear 0s);
	@include size(100%);
	top: 0%; }

// Animation Class
%fadeIn {
	opacity: 1; }
