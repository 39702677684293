/*
	Animation: none
	Source: Dylan Spicker (but like really, no skill needed here) */

// Initial Class
%animationstart_none {
	@include size(0%);
	top: 0%;
	overflow: hidden; }

// Animation Class
%none {
	@include size(100%); }
