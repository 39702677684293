.face-lockup {
  max-width: 780px;
  @include display(flex);
  margin: 0 auto;
  @include align-items(center);
  @include justify-content(space-between);

  .face-img {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-image: url(../img/#{$avatar-path});
    @include size(200px);
    @include order(3); }

  [class^="icon-"] {
    @include size(100px);
    background: $bgcolor;
    border-radius: 50%;

    path {
      fill: $accent; }

    &.icon-Facebook {
      @include order(1); }
    &.icon-Instagram {
      @include order(2); }
    &.icon-Vimeo {
      @include order(4); }
    &.icon-Youtube {
      @include order(5); } } }

@media screen and (max-width: 650px) {
  .face-lockup {
    display: block;
    text-align: center;

    div {
      display: inline-block; }

    .face-img {
      display: block;
      background: {
        position: center center;
        repeat: no-repeat; }
      margin: 0 auto 30px; }

    [class^="icon-"] {
      margin: 10px 9%; } } }




.blurb {
  max-width: 620px;
  padding: 0 20px;
  margin: 50px auto;
  font-size: 24px;
  text-align: center;
  font-weight: 300; }



.skill-lockup {
  max-width: 540px;
  margin: 100px auto;
  color: #999;
  @include clearfix;


  .skill-unit {
    width: 270px;
    float: left; }

  .skill-icons {
    font-size: 0;
    margin: 3px 0 30px;

    span {
      display: inline-block;
      @include size(14px);
      background-color: $accent;
      margin-right: 8px;
      border-radius: 50%;

      &:after {
        content: '';
        display: block;
        @include size(10px);
        background: #fff;
        border-radius: 50%;
        margin: 2px; } }

    @for $i from 1 through 10 {
      &.skill-#{$i} span:nth-child(n+#{$i+1}) {
        @extend %skill-color; } } } }


%skill-color {
  background: $bgcolor; }




@media screen and (max-width: 541px) {
  .skill-lockup {
    width: 220px;

    .skill-unit {
      width: 220px; } } }














