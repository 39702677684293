header {
  @extend %background-image;
  background: {
    position: 30px 20px, 50% 50%;
    image: url(../img/#{$logo-path}), url(../img/#{$hero-path}); }
  padding-bottom: 50px;


  .logo {
    @include size(50px);
    float: left;
    margin: 10px 0 0 30px;

    path {
      fill: rgba(0,0,0,0.4); } }

  nav {
    float: right;
    padding: 30px 30px 0 0;

    a {
      display: inline-block;
      margin-left: 30px;

      color: rgba(255,255,255,0.8);
      @extend %small-bold;

      &:hover {
        color: rgba(255,255,255,1); } } }

  h1 {
    text-align: center;
    font: {
      size: 72px;
      weight: 700; }
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-bottom: 20px;
    clear: both;
    padding-top: 350px;


    span {
      display: inline-block;
      padding: 0.2em 0.5em;
      border: white solid 10px; } }

  .kicker {
    @extend %small-bold;
    text-align: center;
    letter-spacing: 0.05em;
    color: white;
    line-height: 1; } }

@media (max-width: 640px) {
  header {
    background: {
      position: 50% 20px, 50% 50%; }

    nav {
      float: none;
      text-align: center;
      padding-top: 90px; }

    h1 {
      padding-top: 0px; } } }

