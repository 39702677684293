footer {
  @extend %background-image;
  background-image: url(../img/#{$logo-path}), url(../img/#{$hero-path});

  .lockup {
    padding: 50px 30px;
    @include clearfix;

    .content-wrap {
      float: left;
      margin-left: 80px;

      .copyright {
        margin: 0;
        text-transform: uppercase;
        font-size: 12px;
        color: rgba(0,0,0,0.4); }

      a {
        @extend %small-bold;
        color: rgba(255,255,255,0.7);
        display: inline-block;
        margin-right: 30px;

        &:hover {
          color: rgba(255,255,255,0.9); } } } } }
