/*
	Animation: topDown
	Source: Dylan Spicker (but really, Travis Nielson) */

// Initial Conditions
%animationstart_topDown {
	@include size(100%);
	@include position(absolute, -100% 0px null 0px);
	@include transition; }

%topDown {
	top: 0%; }
